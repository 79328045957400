<template>
	<label class="flex flex-wrap">
		<span v-if="label" class="font-semibold block w-full">{{ label }}:</span>
		<select
			:value="modelValue.countryCode"
			:disabled="disabled"
			class="w-24 inline mr-1 h-12"
			:class="{'rounded': rounded}"
			@input="onInputCountryCode"
			@change="onInputCountryCode"
		>
			<option
				v-for="country in countries"
				:key="country.value"
				:value="country.value"
			>
				{{ country.label }}
			</option>
		</select>
		<input
			:value="modelValue.phoneNumber"
			class="flex-grow h-12"
			type="tel"
			:placeholder="placeholder"
			:name="name"
			:class="{ 'error placeholder-red': error, 'rounded': rounded }"
			:disabled="disabled"
			@input="onInputPhoneNumber"
			@change="onInputPhoneNumber"
			@blur="$emit('blur', $event)"
		/>
		<span v-if="error" class="text-red-700 text-sm font-semibold">
			*{{ error }}
		</span>
	</label>
</template>

<script lang="ts">

import type {PropType} from "@vue/runtime-core";
import type {PhoneNumberType} from "~/components/form/models";

export default defineComponent({
	name: 'CustomPhoneInput',
	props: {
		rounded: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			required: false,
			default: ''
		},
		placeholder: {
			type: String,
			default: null,
		},
		error: {
			type: String,
			default: null,
		},
		modelValue: {
			type: Object as PropType<PhoneNumberType>,
            validator: (prop: PhoneNumberType) => {
                return prop.phoneNumber !== undefined && !!prop.countryCode
            },
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, {emit}) {
		const phoneNumber = ref<string>('')
		const selectedCountryCode = ref<string>('+47');
		const countries = reactive([
			{ country: 'Norge', value: '+47', label: '🇳🇴 +47' },
			{ country: 'Sverige', value: '+46', label: '🇸🇪 +46' },
		])
		const name = computed(() =>
			props.label.toLowerCase()
		)

        const updateValue = () => {
            const newValue : PhoneNumberType = {
                phoneNumber: phoneNumber.value.toString(),
				countryCode: selectedCountryCode.value.toString(),
            }
            emit('update:modelValue', newValue)
        }

        const onInputPhoneNumber = (event: any) => {
            phoneNumber.value = event.target.value;
            updateValue()
        }
        const onInputCountryCode = (event: any) => {
            selectedCountryCode.value = event.target.value;
            updateValue()
        }

		return {
			name,
			phoneNumber,
			countries,
            selectedCountryCode,
            onInputPhoneNumber,
            onInputCountryCode
		}
	},
})
</script>
